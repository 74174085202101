.filterTitle:hover{
    font-family: 'Lovelo', sans-serif;
    background: #D9D9D9 !important;
    color: #000;
    padding: 0.5rem 1rem;
    border: none;
    margin-top: 1rem;
    border-radius: 5px;
    text-decoration: none;
}

.filterTitle{
    font-family: 'Lovelo', sans-serif;
    background: #635D5D;
    color: #fff;
    padding: 0.5rem 1rem !important;
    border: none;
    margin-top: 1rem;
    border-radius: 5px;
    text-decoration: none;
}

.formCheck{
    font-family: 'Lovelo', sans-serif;
}

.formCheck:hover{
    
}