.container {
    position: relative;
    /* Other styling */
  }
  

  .filters {
    position: absolute;
    top: 0;
    right: -15rem;
    width: 200px; /* Set this to the width of your filter section */
    /* Add any other styles for your filters here */
 
  }

/* Ensure LocationsList takes the remaining space */
.LocationsList {
  flex-grow: 1;
}




.filterTitle:hover{
    font-family: 'Lovelo', sans-serif;
    background: #D9D9D9 !important;
    color: #000;
    padding: 0.5rem 1rem;
    border: none;
    
    border-radius: 5px;
    text-decoration: none;
}

.filterTitle{
    font-family: 'Lovelo', sans-serif;
    background: #635D5D;
    color: #fff;
    padding: 0.5rem 1rem !important;
    border: none;
   
    border-radius: 5px;
    text-decoration: none;
}


button.btn.btn-primary:hover{
    font-family: 'Lovelo', sans-serif;
    background: #D9D9D9 !important;
    color: black;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    text-decoration: none;
}