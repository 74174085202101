.manage-option-card:hover .card-body{
    background-color: #D9D9D9; /* Light background color on hover for the card */
    transform: scale(1.05); /* Slight scale up for effect */
    transition: transform 0.3s ease-in-out; /* Smooth transition for effects */
    border-radius: none !important;
    border-color: #D9D9D9 !important;
    cursor: pointer;
    border: none !important;

  }
  

  /* meow */

.manage-option-card:hover .card-body,
.manage-option-card:hover .card-title,
.manage-option-card:hover .card-text {
  color: black !important; /* Text color change for all text within the card on hover */
  border: none !important;

}
