/* SearchBarComponent.css */
@font-face {
    font-family: 'Lovelo';
    src: url('../assets/lovelo.otf') format('opentype');
  }
  
  .search-bar-container {
    display: flex;
    justify-content: center;
    margin: 2rem auto;
    max-width: 600px;
  }
  
  .search-input,
  .search-button {
    height: 38px;
    padding: 0.375rem 0.75rem;
    border: 2px solid #635D5D; /* Match button color */
    background-color: #fff; /* White background for the input */
  }
  
  .search-input {
    flex-grow: 1;
    border-right: none; /* Connect to button without double border */
    border-radius: 0.25rem 0 0 0.25rem; /* Rounded corners on the input side */
  }
  
  .search-button {
    border-left: none; /* Connect to input without double border */
    background-color: #635D5D; /* Button background color */
    color: white;
    border-radius: 0 0.25rem 0.25rem 0; /* Rounded corners on the button side */
    font-family: 'Lovelo', sans-serif; /* Custom font */
    transition: background-color 0.3s; /* Smooth color transition */
  }
  
  .search-button:hover {
    background-color: #D9D9D9; /* Button color on hover */
    color: #000; /* Text color on hover */
    border-color: #635D5D; 
  }
  
  /* Removes the glowing border on focus */
  .form-control:focus,
  .btn:focus {
    box-shadow: none;
  }
  
  .search-input:focus {
    border-color: #635D5D;
    outline: none; /* Removes the blue outline */
  }
  
  