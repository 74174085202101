/* Form group with label and input on the same line */
.form-group {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem; /* Space between each form group */
}

/* Style for the label */
.form-label {
    margin-right: 0.5rem; /* Space between the label and the input */
    white-space: nowrap; /* Ensure the label text does not wrap */
}

/* Full width for input */
.form-control {
    flex-grow: 1; /* Allow input to fill the remaining space */
    margin-left: 0.5rem; /* Space between the label and the input */
}

/* You might need to adjust the styling for other form elements like textareas and file inputs */
.form-control-textarea, .form-control-file {
    flex-grow: 1; /* Allow to fill the remaining space */
}

/* Adjust the button to fit in the new layout if necessary */
.btn-save-changes {
    width: auto; /* Adjust the width as needed */
    margin-top: 1rem; /* Space above the button */
}

.card:hover .card-body,
.card:hover .card-title,
.card:hover .card-footer,
.card:hover .card-img-top,
.card:hover .card-text {
  /* Your hover styles go here */
  background-color: #D9D9D9!important;
  color: black !important;
  transform: scale(1.025); /* Slight scale up for effect */

  /* Add other styles as needed */
}


/* Form group with label and input on the same line */
.form-group {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem; /* Space between each form group */
}

/* Style for the label */
.form-label {
    margin-right: 0.5rem; /* Space between the label and the input */
    white-space: nowrap; /* Ensure the label text does not wrap */
}


/* You might need to adjust the styling for other form elements like textareas and file inputs */
.form-control-textarea, .form-control-file {
    flex-grow: 1; /* Allow to fill the remaining space */
}

/* Adjust the button to fit in the new layout if necessary */
.btn-save-changes {
    width: auto; /* Adjust the width as needed */
    margin-top: 1rem; /* Space above the button */
}

.custom-label {
    /* Your styles for the label */
    text-align: right; /* Align text to the right for the labels */
    padding-right: 10px; /* Add some padding to the right of the label */
  }
  
  .custom-input {
    /* Your styles for the input fields */
    border: 1px solid #ccc; /* Add a border */
    border-radius: .25rem; /* Add rounded corners */
  }

  /* Full width for input */
  .form-control {
    border: 2px solid #635D5D; /* Standard input border */
    padding: .375rem .75rem; /* Padding within the input */
    border-radius: .25rem; /* Rounded corners for the input */
    flex-grow: 1; /* Allow input to fill the remaining space */
    margin-left: 0.5rem; /* Space between the label and the input */
}

.text-sm-end {
    padding-right: 0; /* Adjust this as needed based on your design */
}

  

.form-label {
    margin-right: 0.5rem; /* Space between the label and the input */
    white-space: nowrap; /* Ensure the label text does not wrap */
    font-family: 'Lovelo', sans-serif;
    padding: 0.5rem 1rem; /* Space inside the box */
    border: 2px solid #635D5D; /* Border to create the box effect */
    border-radius: 5px; /* Rounded corners for the boxes */
    background: #635D5D !important;
    color: white; /* Default text color */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition for colors */
}
.form-control {
    flex-grow: 1; /* Allow input to fill the remaining space */
    margin-left: 0.5rem; /* Space between the label and the input */
}

/* Form group with label and input on the same line */
.form-group {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem; /* Space between each form group */
}

/* Style for the label */
.form-label {
    margin-right: 0.5rem; /* Space between the label and the input */
    white-space: nowrap; /* Ensure the label text does not wrap */
    font-family: 'Lovelo', sans-serif;
    padding: 0.5rem 1rem; /* Space inside the box */
    border: 2px solid #635D5D; /* Border to create the box effect */
    border-radius: 5px; /* Rounded corners for the boxes */
    background: #635D5D !important;
    color: white; /* Default text color */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition for colors */
}

/* Full width for input */
.form-control {
    flex-grow: 1; /* Allow input to fill the remaining space */
    margin-left: 0.5rem; /* Space between the label and the input */
}

/* You might need to adjust the styling for other form elements like textareas and file inputs */
.form-control-textarea, .form-control-file {
    flex-grow: 1; /* Allow to fill the remaining space */
}

/* Adjust the button to fit in the new layout if necessary */
.btn-save-changes {
    width: auto; /* Adjust the width as needed */
    margin-top: 1rem; /* Space above the button */
}

.input.form-control{
    background-color: #fff;
    border: 2px solid #635D5D !important;
    color: #000;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
}



.text-center{
    font-family: 'Lovelo', sans-serif;
    background-color: #fff;
    color: #000;
    padding: 0.375rem 0.35rem;
    border-radius: 0.25rem;
}