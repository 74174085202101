.about-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: #f2f2f2; /* Replace with the color of your choice */
  }
  
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .profile-picture {
    width: 150px; /* Adjust as needed */
    height: 150px; /* Adjust as needed */
    border-radius: 50%;
    background: #ccc; /* Placeholder color */
    margin-bottom: 1rem;
    object-fit: cover !important; /* Cover the space of the container */
    object-position: center !important; /* Center the image within the element */
    
  }
  
  .profile-name {
    background: #635D5D;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-family: 'Lovelo', sans-serif;
    

  }
  
  .contact-button {
    background: #635D5D;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Lovelo', sans-serif;
    text-decoration: none;

  }

  .contact-button:hover {
    background: #D9D9D9;
    color: #000;
    padding: 0.5rem 1rem;
    border: none;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Lovelo', sans-serif;
  }
  
  .about-section {
    background: white;
    padding: 1rem;
    border-radius: 5px;
  }
  
  .about-title {
    text-align: center;
    font-family: 'Lovelo', sans-serif;
    color: #635D5D;
    margin-bottom: 1rem;
    
  }
  
  .about-content {
    text-align: justify;
    font-family: Arial, sans-serif; /* Replace with your choice of font */
  }
  